<template>
  <div class="stripe-card">
    <b-loading :active="isRequesting" :is-full-page="false"></b-loading>
    <div class="columns">
      <div class="column">
        <label class="label">{{$t("payment.stripe_card_number")}}</label>
        <div ref="cardNumber"></div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">{{$t("payment.stripe_expiration_date")}}</label>
        <div ref="cardExpiry"></div>
      </div>
      <div class="column">
        <label class="label">{{$t("payment.stripe_cvc")}}</label>
        <div ref="cardCvc"></div>
      </div>
    </div>
  </div>
</template>

<script>
import stripeService from "@/web/services/stripe";
import RequestMixin from "@/shared/mixins/RequestMixin";
import Constants from "@/web/constants";

const cardElements = {};

export default {
  mixins: [RequestMixin],

  props: {
    stripePublishKey: {
      type: String,
      required: true,
    },
  },

  requests: {
    async loadStripe() {
      await stripeService.loadStripe(this.stripePublishKey);
      await this.createElements();
      await this.mountElements();
    }
  },

  mounted() {
    if (!window.Stripe) {
      this.loadStripe();
    } else {
      this.mountElements();
    }
  },

  methods: {
    async createElements() {
      const style = {
        base: {
          fontSize: "18px",
          color: "#000000"
        }
      };

      const elements = await stripeService.loadElements();
      cardElements.cardNumber = elements.create("cardNumber", { showIcon: true, style });
      cardElements.cardExpiry = elements.create("cardExpiry", { style });
      cardElements.cardCvc = elements.create("cardCvc", { style });
    },
    async mountElements() {
      cardElements.cardNumber.mount(this.$refs.cardNumber);
      cardElements.cardExpiry.mount(this.$refs.cardExpiry);
      cardElements.cardCvc.mount(this.$refs.cardCvc);
    }
  }
};
</script>

<style lang="scss" scoped>
.stripe-card ::v-deep {
  .StripeElement {
    padding: 8px 15px;
    border: solid 1px #d8d8d8;
    border-radius: 6px;
    cursor: text;
  }

  .StripeElement--invalid {
    border-color: #ff0000;
  }
}
</style>
