<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered">
        {{ title }}
      </h2>
      <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-4">
        <social-link-list :socials="socialLinks"></social-link-list>
      </div>
    </div>
  </article>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import SocialLinkList from "@/web/components/shared/SocialLinkList";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "SocialLinksComponent",
  mixins: [LpConfigMixin],
  components: { Separator, SocialLinkList },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    socialLinksModuleFields: () => LpConfigConstants.SOCIAL_LINKS_MODULE_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    socialLinks() {
      return this.getFieldValueOrDefault(this.moduleData, this.socialLinksModuleFields.SOCIAL_LINKS, []);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },
  },
};
</script>

<style scoped></style>
