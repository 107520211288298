<template>
  <div class="purchase-call-to-action-container">

    <div v-if="isUpgrade2024Event" class="upgrade-2024">
      <img alt="Call to action banner" src="@/assets/custom/upgrade_2024_banner.jpg"/>
      <div class="purchase-overlay-container">
        <div class="purchase-call-to-action-title">{{ $t("custom.upgrade_2024_call_to_action_title") }}</div>
        <div class="purchase-call-to-action-description">{{ $t("custom.upgrade_2024_call_to_action_desc") }}</div>
        <b-button class="button purchase-call-to-action-button" type="is-primary" @click="openLoginModal">{{ $t("auth.log_in") }}</b-button>
      </div>
    </div>

    <template v-else-if="authorizeVisible">
      <div class="purchase-overlay-container">
        <div class="purchase-call-to-action-title">{{ $t("payment.call_to_action_authorize_title") }}</div>
        <div class="purchase-call-to-action-description">{{$t("payment.call_to_action_authorize_description")}}</div>
        <b-button class="button purchase-call-to-action-button" type="is-primary" @click="openLoginModal">{{ $t("auth.log_in") }}</b-button>
      </div>
    </template>
    <template v-else-if="customCallToActionVisible">
      <template v-if="customImageUrl">
        <div class="purchase-overlay-container is-absolute">
          <div class="purchase-call-to-action-title" v-html="customTitle" v-if="customTitle"></div>
          <div class="purchase-call-to-action-description" v-html="customDescription" v-if="customDescription"></div>
        </div>
        <img v-if="customImageUrl" :src="customImageUrl" alt="Call to action banner"/>
      </template>
      <template v-else>
        <div class="purchase-overlay-container">
          <div class="purchase-call-to-action-title" v-html="customTitle" v-if="customTitle"></div>
          <div class="purchase-call-to-action-description" v-html="customDescription" v-if="customDescription"></div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import {mapState} from "vuex";

export default {
  name: "PurchaseCallToAction",
  mixins: [LpConfigMixin],
  props: {
    paymentConfig: {
      type: Object,
      required: false,
    },
    authorizeVisible: {
      type: Boolean,
      required: true, 
    }
  },

  computed: {
    ...mapState(["event"]),
    paymentFields: () => LpConfigConstants.PAYMENT_FIELDS,

    customCallToActionVisible() {
      return !!this.paymentConfig;
    },

    isUpgrade2024Event() {
      return this.event.id === 1530;
    },

    customImageUrl() {
      return this.getCustomTranslation(this.paymentConfig, this.paymentFields.CALL_TO_ACTION_IMAGE_URL_KEY, null);
    },

    customTitle() {
      return this.getCustomTranslation(this.paymentConfig, this.paymentFields.CALL_TO_ACTION_TITLE_KEY, null);
    },

    customDescription() {
      return this.getCustomTranslation(this.paymentConfig, this.paymentFields.CALL_TO_ACTION_DESCRIPTION_KEY, null);
    },

    containerStyle() {
      return {
        backgroundImage: this.customImageUrl ? `url(${this.customImageUrl})` : false,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      };
    },
  },

  methods: {
    openLoginModal() {
      this.$root.openLoginModal();
    },
  },
};
</script>

<style scoped>
.upgrade-2024 {
  padding: 24px;
}

.upgrade-2024 img {
  width: 100%;
}
</style>
