<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <div class="box is-borderless is-clipped has-logo px-0 py-0" v-if="pictureUrl" :style="{'background-color': logoBgColor + '!important'}">
        <figure class="image is-centered">
          <img class="has-image-centered" :src="pictureUrl"/>
        </figure>
      </div>
      <button class="delete" type="button" @click="$parent.close"></button>
      <div class="title">{{ fullName }}</div>
      <div class="subtitle">{{ subtitle }}</div>
    </header>

    <section class="modal-card-body">
      <div v-if="description">
        <div class="section-text" v-html="description"></div>
      </div>
      <template v-if="externalUrl">
        <div class="section-title mb-1">
          {{ $t("common.contact_website") }}
        </div>
        <a :href="externalUrl" target="_blank" class="section-text has-text-link" rel="noopener noreferrer">
          {{ externalUrl }}
        </a>
      </template>

      <div v-if="showContactList && contacts.length > 0">
        <div class="section-title mb-1">
          {{ $t("person_profile.user_contact_details") }}
        </div>
        <template v-for="(contact, index) in contacts">
          <contact-link v-bind="contact" :key="index"></contact-link>
        </template>
      </div>
      <section v-if="contactPersons.length > 0">
        <h2 class="section-title mb-1">{{ $t("common.contact_person") }}</h2>
        <paginated-content :items="contactPersons" :perPage="2">
          <template v-slot="{ displayItems }">
            <div class="is-flex is-flex-wrap">
              <div v-for="(contact, index) in displayItems" :key="index" class="is-flex-1">
                <contact-card v-bind="contact" :inbox-enabled="inboxEnabled"></contact-card>
              </div>
            </div>
          </template>
        </paginated-content>
      </section>
      <div class="mt-2" v-if="showSocialMedia && socialLinks.length > 0">
        <social-links class="contact-links" size="40" :links="socialLinks"></social-links>
      </div>
    </section>

    <footer class="modal-card-foot" :style="{ padding: '5px' }"></footer>
  </div>
</template>

<script>
import {buildContactList, buildSocialLinks, buildContactModels} from "@/shared/utils";
import SocialLinks from "@/web/components/shared/SocialLinks";
import ContactLink from "@/web/components/shared/ContactLink";
import {mapGetters} from "vuex";
import PaginatedContent from "@/shared/components/common/pagination/PaginatedContent";
import ContactCard from "@/web/components/shared/ContactCard";

export default {
  name: "PartnerInfoModal",

  components: {SocialLinks, ContactLink, ContactCard, PaginatedContent},

  props: ["partner", "logoBgColor"],

  computed: {
    ...mapGetters(["inboxEnabled"]),
    ...mapGetters("auth", ["isAuthorized"]),

    fullName() {
      return this.partner.name;
    },

    subtitle() {
      return this.partner.subname;
    },

    pictureUrl() {
      return this.partner.picture && this.partner.picture.file_thumbnail_750_url;
    },

    initials() {
      return "";
    },

    description() {
      return this.partner.description && this.$options.filters.linkify(this.partner.description);
    },

    socialLinks() {
      return buildSocialLinks(this.partner);
    },

    contacts() {
      return buildContactList(this.partner);
    },

    showContactList() {
      return !this.partner.for_logged_contact || this.isAuthorized;
    },

    showSocialMedia() {
      return !this.partner.for_logged_sm || this.isAuthorized;
    },

    contactPersons() {
      return buildContactModels(this.partner.partners_contact_people);
    },

    externalUrl() {
      const url = this.partner.www;
      const hasHttp = url && url.startsWith("http");
      return hasHttp && this.partner.www;
    },
  },
};
</script>

<style scoped>
.modal-card-body {
  padding-bottom: 30px !important;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  margin-top: 12px;
}

.subtitle {
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  text-align: center;
  font-feature-settings: "liga" off;
  color: #828282;
  margin-top: 8px;
}

.section-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin-top: 31px;
  line-height: 27px;
  font-feature-settings: "liga" off;
  color: var(--text-color-primary);
}

.section-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  color: var(--text-color-secondary);
  margin-top: 6px;
  white-space: pre-line;
}

.contact-links {
  align-items: center;
  justify-content: center;
}

.box {
  height: 190px;
}

.image {
  display: flex;
  align-items: center;
  height: 100%;
}

.image img {
  margin: 0 auto;
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
}
</style>
