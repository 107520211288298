<template>
  <div class="event-date">
    <p class="is-size-5 has-text-weight-bold" :style="textColor">
      <location-icon :style="textColor" class="location-icon mr-1"></location-icon>
      {{ location }}
    </p>
  </div>
</template>

<script>
import LocationIcon from "@/assets/icon_location_pin.svg";

export default {
  name: "EventLocation",

  components: {
    LocationIcon
  },

  props: ["event", "textColor"],

  computed: {
    location() {
      if(this.event.id === 1387) {
        return "Pałac Kultury i Nauki, Warszawa, Polska";
      } else if (this.event.id === 1584) {
        return "Hotel The Bridge Wrocław, Plac Katedralny 8, 50-329 Wrocław, Polska";
      } else {
        return this.event.place_address;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.location-icon {
  margin-top: -6px;
  vertical-align: middle;
}
</style>
