<template>
  <article class="module is-relative" :class="{ 'is-pism': isPismEvent, 'is-dolby': isDolbyEvent, 'timer-hidden': !timerVisible }">
    <img :src="eventBannerUrl" v-if="autoResizeEnabled" :style="autoResizeImageStyle" @click="onBannerClick" />
    <div class="hero" :style="heroStyle" @click="onBannerClick" v-else>
      <div class="hero-body" v-if="showDetails" :class="{'timer-hidden': !timerVisible}">
        <div class="container has-text-centered">
         <!-- <google-banner class="mb-4" v-if="isGoogleSummit || isGoogleSummit2024"></google-banner> -->
          <img class="event-logo" :src="eventLogo" v-if="eventLogo" />
          <h1 class="title primary-text-color is-1 mb-4" :style="textColor" v-else>
            {{ title }}
          </h1>
          <div class="columns is-centered is-vcentered mb-4 is-multiline" v-if="isDolbyEvent">
            <div class="column is-full px-2">
              <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                <event-date :text-color="textColor" :event="event"></event-date>
                <span :style="textColor" class="event-date is-size-5 has-text-weight-bold ml-1">14:00 - 20:00</span>
              </div>
            </div>
            <div class="column is-full px-2">
              <event-location :text-color="textColor" :event="event"></event-location>
            </div>
          </div>
          <div class="columns is-centered is-vcentered mb-4" v-else>
            <div class="column is-narrow px-2" v-if="locationVisible">
              <event-location :text-color="textColor" :event="event"></event-location>
            </div>
            <div class="column is-narrow px-2" v-if="dateVisible">
              <event-date :text-color="textColor" :event="event"></event-date>
            </div>
          </div>
          <separator v-if="!isGoogleSummit && !isGoogleSummit2024" :style="textColor" class="separator"></separator>
        </div>
      </div>
    </div>

    <div
      class="event-timer-wrapper"
      :class="{ 'pism-timer-wrapper': isPismEvent, 'dolby-timer-wrapper': isDolbyEvent }"
      v-if="timerVisible"
    >
      <event-timer :date="timerDate"></event-timer>

      <div class="has-text-centered mt-2" v-if="timerBottomText" :style="timerBottomTextStyle">{{ timerBottomText }}</div>
    </div>
  </article>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Separator from "@/web/components/shared/Separator";
import EventLocation from "@/web/components/eventinfo/EventLocation";
import EventDate from "@/web/components/eventinfo/EventDate";
import EventTimer from "./eventinfo/EventTimer";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import Constants from "@/web/constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import { throttle } from "@/shared/utils";

export default {
  name: "EventInfoComponent",
  mixins: [LpConfigMixin],
  components: {
    Separator,
    EventLocation,
    EventDate,
    EventTimer,
  },

  data() {
    return {
      isMobile: false,
    };
  },

  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  computed: {
    ...mapState(["event", "eventColors"]),
    ...mapGetters("agendaSessionQuestions", ["getFirstSession"]),
    ...mapGetters("components", ["getComponentById"]),
    ...mapGetters(["lpSettings"]),
    ...mapState("locales", ["currentLocale"]),

    isPismEvent() {
      return this.event.id === 1312;
    },

    isDolbyEvent() {
      return this.event.id === 1318;
    },

    isGoogleSummit() {
      return this.event.id === 1387;
    },

    isGoogleSummit2024() {
      return this.event.id === 1623;
    },

    headerConfig() {
      return this.lpSettings[LpConfigConstants.ROOT_FIELDS.HEADER] || {};
    },

    timerConfig() {
      return this.lpSettings[LpConfigConstants.ROOT_FIELDS.TIMER] || {};
    },

    heroStyle() {
      const headerFields = LpConfigConstants.HEADER_FIELDS;
      if (this.isGoogleSummit || this.isGoogleSummit2024) {
        return {};
      } else {
        return {
          backgroundImage: this.bannerImage,
          backgroundPosition: this.headerConfig[headerFields.IMAGE_POSITION] || "center",
          backgroundSize: this.headerConfig[headerFields.IMAGE_SIZE] || "cover",
          backgroundRepeat: this.headerConfig[headerFields.IMAGE_REPEAT] || "no-repeat",
          minHeight: this.headerConfig[headerFields.HEIGHT] || null,
          backgroundColor: this.headerConfig[headerFields.BACKGROUND_COLOR] || "whitesmoke",
          color: this.textColor,
          height: "auto",
          cursor: "default",
        };
      }
    },

    eventLogo() {
      return this.headerConfig[LpConfigConstants.HEADER_FIELDS.EVENT_LOGO_URL];
    },

    eventBanner() {
      return (
        this.getCustomTranslation(this.headerConfig, LpConfigConstants.HEADER_FIELDS.IMAGE_URL) ||
        (this.event.lp_banner && this.event.lp_banner.file_url) ||
        Constants.DEFAULT_BANNER_IMAGE_URL
      );
    },

    eventBannerUrl() {
       if (this.eventBanner) {
        return this.eventBanner;
      } else {
        return null;
      }
    },

    bannerImage() {
      if (this.eventBannerUrl) {
        return `url("${this.eventBannerUrl}")`;
      } else {
        return null;
      }
    },

    autoResizeImageStyle() {
      const headerFields = LpConfigConstants.HEADER_FIELDS;
      return {
        paddingTop: this.headerConfig[headerFields.IMAGE_AUTO_RESIZE_MARGIN_TOP] || 0,
        paddingBottom: this.headerConfig[headerFields.IMAGE_AUTO_RESIZE_MARGIN_BOTTOM] || 0,
        paddingRight: this.headerConfig[headerFields.IMAGE_AUTO_RESIZE_MARGIN_RIGHT] || 0,
        paddingLeft: this.headerConfig[headerFields.IMAGE_AUTO_RESIZE_MARGIN_LEFT] || 0,
        cursor: "default",
      };
    },

    showDetails() {
      const showDetails = this.headerConfig[LpConfigConstants.HEADER_FIELDS.SHOW_DETAILS];
      if (typeof showDetails !== "undefined") {
        return !!showDetails;
      } else {
        return true;
      }
    },

    locationVisible() {
      const showLocation = this.headerConfig[LpConfigConstants.HEADER_FIELDS.SHOW_LOCATION];
      if (typeof showLocation !== "undefined") {
        return !!showLocation;
      } else {
        return true;
      }
    },

    dateVisible() {
      const showDate = this.headerConfig[LpConfigConstants.HEADER_FIELDS.SHOW_DATE];
      if (typeof showDate !== "undefined") {
        return !!showDate;
      } else {
        return true;
      }
    },

    title() {
      return this.event.title;
    },

    timerDate() {
      return (
        this.timerConfig[LpConfigConstants.TIMER_FIELDS.DATE] ||
        (this.getFirstSession && this.getFirstSession.time_start) ||
        this.event.start_date
      );
    },

    timerVisible() {
      return !this.timerConfig[LpConfigConstants.TIMER_FIELDS.HIDDEN];
    },

    timerBottomText() {
      return this.getCustomTranslation(this.timerConfig, LpConfigConstants.TIMER_FIELDS.BOTTOM_TEXT, null);
    },

    timerBottomTextStyle() {
      return {
        color: this.timerConfig[LpConfigConstants.TIMER_FIELDS.BOTTOM_TEXT_COLOR] || "#000000",
        fontSize: this.timerConfig[LpConfigConstants.TIMER_FIELDS.BOTTOM_TEXT_SIZE] || "14px",
      };
    },

    textColor() {
      const textColor = this.headerConfig[LpConfigConstants.HEADER_FIELDS.TEXT_COLOR] || "#ffffff";
      return {
        color: textColor,
        fill: textColor,
      };
    },

    autoResizeEnabled() {
      return this.headerConfig[LpConfigConstants.HEADER_FIELDS.IMAGE_AUTO_RESIZE];
    },
  },

  methods: {
    onBannerClick() {
      //no-op
    },

    onResize: throttle(function (newVal) {
      const newIsMobile = window.innerWidth < 650;
      if (this.isMobile !== newIsMobile) {
        this.isMobile = newIsMobile;
      }
    }, 80),
  },
};
</script>

<style lang="scss" scoped>
.module {
  margin-bottom: 150px;

  &.timer-hidden {
    margin-bottom: 0;
  }

  &.is-pism {
    margin-bottom: 230px;

    @media screen and (max-width: 968px), print {
      margin-bottom: 340px;
    }

    @media screen and (max-width: 600px), print {
      margin-bottom: 350px;
    }
  }

  &.is-dolby {
    margin-bottom: 300px;

    @media screen and (max-width: 968px), print {
      margin-bottom: 260px;
    }

    @media screen and (max-width: 600px), print {
      margin-bottom: 240px;
    }
  }
}

//.hero {
//  background-position: center;
//  background-size: cover;
//}

.hero-body {
  padding: 160px 0 100px 0;

  &.timer-hidden {
    padding-bottom: 80px;
  }
}

.title {
  font-weight: 900;
}

.event-timer-wrapper {
  transform: translateY(50%);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.pism-timer-wrapper {
    transform: translateY(70%);
  }

  &.dolby-timer-wrapper {
    transform: translateY(70%);
  }

  @media screen and (max-width: 968px), print {
    transform: translateY(92%);

    &.pism-timer-wrapper {
      transform: translateY(90%);
    }

    &.dolby-timer-wrapper {
      transform: translateY(65%);
    }
  }
}

// Hide separating only on next .module element
// Allows composing components in non-standard ordering
.module ::v-deep + .module {
  .container > .separator {
    display: none;
  }
}

img {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.event-logo {
  max-width: 352px;
  height: auto;
  width: 100%;
  margin-bottom: 43px;
}

.google-header {
  position: absolute;
  right: -5px;
  top: 60px;
}
@media only screen and (max-width: 1355px) {
  .google-header {
    display: none;
  }
  .google-footer-2 {
    display: none;
  }
}
</style>
