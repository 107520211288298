<template>
  <form @change="input" @submit.prevent>
    <!-- Invoice toggle checkbox -->
    <div class="row">
      <div class="fieldset">
        <div class="field">
          <div class="control is-size-7 has-text-primary has-text-weight-bold">
            <label class="checkbox">
              <checkbox class="checkbox-input" v-model="want_invoice"></checkbox>
              <span class="consents" @click="handleWantInvoiceChange">
                {{ $t("payment.company_invoice") }}
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="fieldset">
        <div class="field">
          <div class="control is-size-7 has-text-primary has-text-weight-bold">
            <label class="checkbox">
              <checkbox class="checkbox-input" v-model="want_personal_invoice"></checkbox>
              <span class="consents" @click="handleWantPersonalInvoiceChange">
                {{ $t("payment.personal_invoice") }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <template v-if="$v.want_invoice_or_personal_invoice.$error">
      <p class="help is-danger">{{ $t("common.invoice_type_selection_required") }}</p>
    </template>

    <div class="fieldset mt-3" v-if="want_invoice && proformaVisible">
      <div class="field">
        <div class="control is-size-7 has-text-primary has-text-weight-bold">
          <label class="checkbox">
            <checkbox class="checkbox-input" v-model="want_proforma_invoice"></checkbox>
            <span class="consents" @click="handleWantProformaClick">
              {{ $t("payment.invoice_proforma") }}
            </span>
          </label>
        </div>
      </div>
    </div>

    <!-- Invoice fields -->
    <div v-if="want_invoice" class="fieldset mt-3">
      <h3 class="title is-6">{{ $t("payment.invoice_details") }}</h3>
      <div class="columns is-flex-wrap mt-1">
        <div class="column is-half" style="display: flex; flex-direction: column; justify-content: flex-end">
          <div class="field is-fullwidth">
            <label class="label has-text-primary">{{ $t("payment.invoice_nip") }}</label>
            <div class="control">
              <input
                class="input"
                type="text"
                :placeholder="$t('payment.invoice_nip')"
                v-model="$v.invoice_nip.$model"
                :disabled="viesData"
              />
            </div>
            <template v-if="$v.invoice_nip.$error">
              <p v-if="!$v.invoice_nip.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
            </template>
            <template v-if="viesErrorMessage">
              <p class="help is-danger">{{ viesErrorMessage }}</p>
            </template>
          </div>
        </div>
        <div class="column is-half">
          <div class="is-flex is-flex-direction-column is-fullwidth is-justify-content-end has-fullheight">
            <template v-if="!viesData">
              <!--                        <b-button class="button is-outlined" size="is-small" type="is-primary" @click="fillWithDataFromGus">{{-->
              <!--                          $t("payment.invoice_download_from_gus")-->
              <!--                        }}</b-button>-->
              <b-button
                class="form-button is-outlined mt-1"
                size="is-small"
                type="is-primary"
                @click="fillWithDataFromVies"
                :disabled="isRequesting"
              >
                <template v-if="isRequesting">
                  <list-loading-indicator :active="true"></list-loading-indicator>
                </template>
                <template v-else>
                  {{ $t("payment.invoice_download_from_vies") }}
                </template>
              </b-button>
              <b-button
                class="form-button is-outlined mt-1"
                size="is-small"
                type="is-primary"
                v-if="isSmakkiEvent"
                @click="fillWithDataFromPreviousStep"
              >
                {{ $t("custom.smakki_fill_from_previous_step") }}
              </b-button>
            </template>

            <b-button class="form-button is-outlined mt-1" type="is-primary" @click="fillDataManually" v-else>
              {{ $t("payment.invoice_fill_manually") }}
            </b-button>
          </div>
        </div>
        <div class="column is-full">
          <div class="field">
            <label class="label has-text-primary">{{ $t("edit_profile.edit_profile_my_company_hint") }}</label>
            <div class="control">
              <input
                class="input"
                type="text"
                :placeholder="$t('edit_profile.edit_profile_my_company_hint')"
                :disabled="viesData"
                v-model="$v.invoice_company_name.$model"
              />
            </div>
            <template v-if="$v.invoice_company_name.$error">
              <p v-if="!$v.invoice_company_name.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
            </template>
          </div>
        </div>

        <div class="column is-full" v-if="viesData">
          <div class="field">
            <label class="label has-text-primary">{{ $t("payment.invoice_address") }}</label>
            <div class="control">
              <input
                class="input"
                type="text"
                :placeholder="$t('payment.invoice_address')"
                v-model="$v.invoice_street.$model"
                :disabled="true"
              />
            </div>
            <template v-if="$v.invoice_street.$error">
              <p v-if="!$v.invoice_street.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
            </template>
          </div>
        </div>
        <div class="column is-half" v-else>
          <div class="field">
            <label class="label has-text-primary">{{ $t("payment.invoice_street") }}</label>
            <div class="control">
              <input class="input" type="text" :placeholder="$t('payment.invoice_street')" v-model="$v.invoice_street.$model" />
            </div>
            <template v-if="$v.invoice_street.$error">
              <p v-if="!$v.invoice_street.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
            </template>
          </div>
        </div>
        <div class="column is-half" v-if="!viesData">
          <div class="field">
            <label class="label has-text-primary">{{ $t("payment.invoice_building") }}</label>
            <div class="control">
              <input class="input" type="text" :placeholder="$t('payment.invoice_building')" v-model="$v.invoice_street_no.$model" />
            </div>
            <template v-if="$v.invoice_street_no.$error">
              <p v-if="!$v.invoice_street_no.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
            </template>
          </div>
        </div>
        <div class="column is-half" v-if="!viesData">
          <div class="field">
            <label class="label has-text-primary">{{ $t("payment.invoice_city") }}</label>
            <div class="control">
              <input class="input" type="text" :placeholder="$t('payment.invoice_city')" v-model="$v.invoice_city.$model" />
            </div>
            <template v-if="$v.invoice_city.$error">
              <p v-if="!$v.invoice_city.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
            </template>
          </div>
        </div>
        <div class="column is-half" v-if="!viesData">
          <div class="field">
            <label class="label has-text-primary">{{ $t("payment.invoice_postal_code") }}</label>
            <div class="control">
              <input class="input" type="text" :placeholder="$t('payment.invoice_postal_code')" v-model="$v.invoice_post_code.$model" />
            </div>
            <template v-if="$v.invoice_post_code.$error">
              <p v-if="!$v.invoice_post_code.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
            </template>
          </div>
        </div>
        <div class="column is-half" v-if="!viesData">
          <div class="field">
            <label class="label has-text-primary">{{ $t("payment.invoice_state") }}</label>
            <div class="control">
              <input class="input" type="text" :placeholder="$t('payment.invoice_state')" v-model="$v.invoice_state.$model" />
            </div>
            <template v-if="$v.invoice_state.$error">
              <p v-if="!$v.invoice_state.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
            </template>
          </div>
        </div>
        <div class="column is-half" v-if="!viesData">
          <div class="field">
            <label class="label has-text-primary">{{ $t("payment.invoice_country") }}</label>
            <div class="control">
              <input class="input" type="text" :placeholder="$t('payment.invoice_country')" v-model="$v.invoice_country.$model" />
            </div>
            <template v-if="$v.invoice_country.$error">
              <p v-if="!$v.invoice_country.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Checkbox from "@/shared/components/form/Checkbox";
import { mapActions, mapState } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import PaymentConstants from "@/shared/constants/payment-constants";

export default {
  name: "InvoiceForm",
  mixins: [RequestMixin],

  props: ["value"],

  components: { ListLoadingIndicator, Checkbox },

  data() {
    return {
      want_invoice: false,
      want_personal_invoice: false,
      want_proforma_invoice: false,
      viesData: null,
      viesNipValidationError: false,
      invoice_company_name: null,
      invoice_nip: "",
      invoice_street: null,
      invoice_street_no: null,
      invoice_city: null,
      invoice_post_code: null,
      invoice_country: null,
      invoice_state: null,
      invoiceSelectRequired: true,
    };
  },

  validations() {
    let validations = {
      invoice_company_name: { required },
      invoice_nip: { required },
      invoice_street: { required },
    };

    let invoiceValidations = {
      want_invoice_or_personal_invoice: {
          isSelected: () => this.want_invoice || this.want_personal_invoice,
      },
    };

    let validationsWithoutFillingData = {
      invoice_street_no: { required },
      invoice_city: { required },
      invoice_post_code: { required },
      invoice_country: { required },
      invoice_state: { required },
    };
    if (!this.viesData) {
      return { ...validations, ...validationsWithoutFillingData, ...invoiceValidations };
    } else {
      return { ...validations, ...invoiceValidations };
    }
  },

  requests: {
    async fillWithDataFromVies() {
      if (!new RegExp(/^([a-zA-Z][a-zA-Z])(([a-zA-Z0-9]){7,})$/).test(this.invoice_nip)) {
        this.viesNipValidationError = true;
      } else {
        let response = await this.checkVatNumberInVies(this.invoice_nip);
        if (response && response.isValid) {
          this.viesData = response;
          this.invoice_company_name = this.viesData.name;
          this.invoice_street = this.viesData.address.replace(/\n/g, ",");
          this.invoice_post_code = "";
          this.invoice_street_no = "";
          this.invoice_city = "";
          this.invoice_country = "";
          this.invoice_state = "";
          this.touch();
          this.emitUpdate();
          this.viesNipValidationError = false;
        } else {
          this.error = true;
        }
      }
    },
  },

  mounted() {
    // Fix empty invoice state
    setTimeout(() => this.input(), 0);
  },

  computed: {
    ...mapState(["settings", "eventId"]),
    ...mapState("payment", ["personalForm", "additionalForm"]),

    isSmakkiEvent() {
      return this.eventId === 1251;
    },

    viesErrorMessage() {
      if (this.error) {
        return this.$t("payment.invoice_nip_vies_invalid_error");
      } else if (this.viesNipValidationError) {
        return this.$t("payment.invoice_nip_invalid_format_error");
      } else {
        return null;
      }
    },

    proformaVisible() {
      return this.settings?.payment?.gateways?.includes(PaymentConstants.GATEWAY_PROFORMA_ID);
    }
  },

  methods: {
    ...mapActions("payment", ["checkVatNumberInVies"]),

    handleWantInvoiceChange() {
      this.want_invoice = !this.want_invoice;
    },

    handleWantPersonalInvoiceChange() {
      this.want_personal_invoice = !this.want_personal_invoice;
    },

    input() {
      this.emitUpdate();
    },

    handleWantProformaClick() {
      this.want_proforma_invoice = !this.want_proforma_invoice;
      this.input();
    },

    emitUpdate() {
      const formData = {
        want_invoice: this.want_invoice,
        want_personal_invoice: this.want_personal_invoice,
        invoice_company_name: this.invoice_company_name,
        invoice_nip: this.invoice_nip,
        invoice_street: this.invoice_street,
        invoice_street_no: this.invoice_street_no,
        invoice_city: this.invoice_city,
        invoice_post_code: this.invoice_post_code,
        invoice_state: this.invoice_state,
        invoice_country: this.invoice_country,
        want_proforma_invoice: this.want_proforma_invoice,
      };
      this.$emit("input", formData);
    },

    touch() {
      this.$v.$touch();
      this.$v.want_invoice_or_personal_invoice?.$touch();
      // eslint-disable-next-line prettier/prettier
      this.$nextTick().then(() => this.focusInvalidInput());
    },

    focusInvalidInput() {
      const input = this.$el.querySelector("input.is-danger");
      if (input) {
        input.focus();
      }
    },

    fillDataManually() {
      this.viesData = null;
      this.error = null;
      this.invoice_street = null;
      this.touch();
    },

    fillWithDataFromPreviousStep() {
      this.invoice_company_name = this.additionalForm.smakki_company;
      this.invoice_city = this.additionalForm.smakki_town;
      this.invoice_street = this.additionalForm.smakki_street;
      this.invoice_post_code = this.additionalForm.smakki_postal_code;
    },
  },

  watch: {
    want_invoice: {
      handler: function (newValue) {
        if (newValue) {
          this.want_personal_invoice = false;
        }
        this.emitUpdate();
      },
    },
    want_personal_invoice: {
      handler(newValue) {
        if (newValue) {
          this.want_invoice = false;
        }
        this.emitUpdate();
      },
    },
    want_proforma_invoice: {
      handler(newValue) {
        this.input();
      }
    },
    invoice_nip: {
      handler: function (newValue) {
        if (this.viesNipValidationError || this.error) {
          this.viesNipValidationError = false;
          this.error = null;
        }
      },
    },
    value: {
      handler(value) {
        // Copy only known properties in $data
        // eslint-disable-next-line prettier/prettier
        Object.keys(this.$data).forEach(key => (this.$data[key] = valueOrDefault(value && value[key], this.$data[key])));

        // Fix invoice flag value on null
        this.want_invoice = this.want_invoice || false;
        this.want_personal_invoice = this.want_personal_invoice || false;
        this.want_proforma_invoice = this.want_proforma_invoice || false;
      },
      immediate: true,
    },
    "$v.$invalid": {
      handler(invalid) {
        this.$emit("validation", invalid);
      },
      immediate: true,
    },
  },
};

function valueOrDefault(value, defaultValue = null) {
  return typeof value === "undefined" ? defaultValue : value;
}
</script>

<style lang="scss" scoped>
.consents {
  font-size: 15px;
}

.row {
  display: flex;
  gap: 20px;
  align-items: center;
}
</style>
