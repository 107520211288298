<template>
  <div class="columns is-vcentered is-mobile is-multiline is-centered">
    <div :class="columnClass" v-for="partner in partners" :key="partner.id">
      <partner-view :partner="partner" :level="level" :component-id="componentId" :open-external-link="openExternalLink" :bg-card-color="bgCardColor"></partner-view>
    </div>
  </div>
</template>

<script>
import PartnerView from "@/web/components/partners/PartnerView";

export default {
  name: "PartnersView",

  components: { PartnerView },

  props: {
    category: {
      type: Object,
      required: true,
    },

    componentId: {
      type: Number,
      required: true,
    },

    openExternalLink: {
      type: Boolean,
      default: false,
    },

    bgCardColor: {
      type: String,
      default: "#ffffff",
    }
  },

  computed: {
    level() {
      return this.category.level;
    },

    partners() {
      let partners = this.category.partners;
      return partners.sort((a, b) => a.order - b.order);
    },

    columnClass() {
      const level = this.level;
      if (level === 1) {
        return "column is-one-quarter-tablet is-narrow-mobile";
      } else if (level === 2) {
        return "column is-one-third-tablet is-narrow-mobile";
      } else if (level === 3) {
        return "column is-half-tablet is-narrow-mobile";
      } else {
        return "column is-one-quarter";
      }
    },
  },
};
</script>

<style scoped></style>
