<template>
  <div class="carousel-slide" :class="{ 'has-arrows': false }">
    <div class="columns is-gapless is-vcentered is-mobile">
      <div class="column">
        <div class="">
          <b-carousel-list
            :arrow="false"
            :arrow-hover="false"
            :items-to-show="partnersPerPage"
            :repeat="true"
            :data="partners"
            v-model="selectedIndex"
            class="is-shadowless"
          >
            <template slot="item" slot-scope="partner">
              <partner-view :partner="partner" :level="3" :component-id="componentId" :open-external-link="openExternalLink" :is-borderless="true"></partner-view>
            </template>
          </b-carousel-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PartnerView from "@/web/components/partners/PartnerView";

export default {
  name: "PartnersSliderView",
  components: { PartnerView },
  props: {
    partners: {
      type: Array,
      required: true,
    },

    componentId: {
      type: Number,
      required: true,
    },

    openExternalLink: {
      type: Boolean,
      required: true,
    },

    isMobile: {
      type: Boolean,
      required: true,
    },
    bgCardColor: {
      type: String,
      default: "#ffffff",
    }
  },

  data() {
    return {
      selectedIndex: 0,
      timeUpdatesRunning: false,
      ongoingTimeout: null,
    };
  },

  computed: {
    partnersPerPage() {
      if (this.isMobile) {
        return 2;
      } else {
        return 5;
      }
    },
  },

  methods: {
    startTimeUpdates() {
      this.timeUpdatesRunning = true;
      this.nextUpdate();
    },

    stopTimeUpdates() {
      this.timeUpdatesRunning = false;
      clearTimeout(this.ongoingTimeout);
    },

    nextUpdate() {
      if (this.timeUpdatesRunning) {
        this.animateNextPage();
        this.ongoingTimeout = setTimeout(() => this.nextUpdate(), 3000);
      }
    },

    animateNextPage() {
      let newIndex = this.selectedIndex + 1;
      if (newIndex === this.partners.length - this.partnersPerPage - 1) {
        this.selectedIndex = -1;
      } else this.selectedIndex = newIndex;
    },
  },

  mounted() {
    this.startTimeUpdates();
  },

  beforeDestroy() {
    this.stopTimeUpdates();
  },
};
</script>

<style scoped lang="scss">
.column {
  min-width: 0;
}
</style>
