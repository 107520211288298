<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>

      <div class="ped-ticket-container">
        <div class="ped-ticket-content">
          <h2 class="ped-ticket-title">{{$t("custom.ped_ticket_get_for_free")}} <span class="bold">{{$t("custom.ped_ticket_get_for_free_bolded")}}</span></h2>
          <event-ticket-view @click="openTicket" v-if="ticket !== undefined" class="ped-ticket-ticket" :ticket="ticket" :disabled="false" :eventTicketsVat="eventTicketsVat" :ticketViewType="ticketViewType"></event-ticket-view>
        </div>
      </div>

      <p class="ped-ticket-additional-info">{{$t("custom.ped_ticket_additional_info_text")}}</p>
    </div>
  </article>
</template>

<script>

import { mapGetters, mapState } from "vuex";
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import EventTicketView from '@/web/components/eventtickets/EventTicketView';
import Constants from "@/web/constants";

export default {
  name: "PedTicketComponent",
  mixins: [LpConfigMixin],
  components: { Separator, EventTicketView },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters(["lpSettings", "eventTicketsVat"]),
    ...mapGetters("eventTickets", ["getAllTickets"]),

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    pageSize() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.PAGE_SIZE, 8);
    },

    ticketViewType() {
      return LpConfigConstants.EVENT_TICKET_VIEW_TYPES.CINEMA;
    },

    ticket() {
      let tickets = this.getAllTickets;
      return tickets && tickets[0];
    }
  },

  methods: {
    openTicket(ticketId) {
      const buyTicketRoute = { name: Constants.ROUTE_BUY_TICKET, params: { ticketId } };
      this.$router.push(buyTicketRoute);
    }
  }
};
</script>

<style scoped>
  .bold {
    font-weight: 700;
  }

  .container {
    padding-top: 80px;
  }

  .ped-ticket-additional-info {
    color: #e0e0e0;
    text-align: center;
  }

  .ped-ticket-title {
    color: #F2F2F2;
    font-size: 48px;
    line-height: 130%;
  }

  .ped-ticket-container {
    background-image: url(~@/assets/custom/ped/arrow.png);
    background-position: center center;
  }

  .ped-ticket-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .ped-ticket-ticket {
    width: 290px;
  }


  @media(max-width: 896px) {
    .ped-ticket-container {
      background-image: none;
    }

    .ped-ticket-title {
      font-size: 36px;
      margin-right: 20px;
    }
  }

  @media(max-width: 620px) {
    .ped-ticket-content {
      display: block;
    }
    .ped-ticket-ticket {
      width: 80%;
      margin: 0 auto;
    }

    .ped-ticket-title {
      text-align: center;
      margin-bottom: 40px;
    }
  }
</style>
