<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>
      <div v-if="isLoadingExhibitors">
        <list-loading-indicator :active="true"></list-loading-indicator>
      </div>
      <div v-else>
        <div class="columns is-vcentered mb-2">
          <div class="column is-three-fifths">
            <search-input :placeholder="$t('hints.search_hint_exhibitors')" v-model="search"></search-input>
          </div>
          <div class="column is-two-fifths">
            <dropdown-select :items="allTags" :multiple="true" :value="selectedTagsIndexes" @input="selectTags">
              <!-- Path tag items -->
              <template #dropdown="{ item }">
                <div class="mr-1">
                  <point-icon class="point-icon mr-1" :style="{ fill: item.hex }"></point-icon>
                  <span>{{ item.value.toUpperCase() }}</span>
                </div>
              </template>

              <template #trigger="{ item }">
                <div class="mr-1">
                  <point-icon class="point-icon mr-1" :style="{ fill: item.hex }"></point-icon>
                  <span>{{ item.value.toUpperCase() }}</span>
                </div>
              </template>

              <!-- Placeholder text -->
              <template #placeholder>
                <span>{{ $t("common.filter_by") }}</span>
              </template>
            </dropdown-select>
          </div>
        </div>
        <exhibitors-pager-view
          :exhibitors="exhibitors"
          :page-size="pageSize"
          :is-authorized="isAuthorized"
          :lp-mode="true"
          :inbox-enabled="inboxEnabled"
          :fluid-number-of-columns="false"
          @tag-click="onTagClicked"
        ></exhibitors-pager-view>
      </div>
    </div>
  </article>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import DropdownSelect from "@/shared/components/DropdownSelect";
import PointIcon from "@/assets/icons/point.svg";
import Separator from "@/web/components/shared/Separator";
import SearchInput from "@/shared/components/SearchInput";
import ExhibitorsPagerView from "@/shared/components/exhibitors/ExhibitorsPagerView";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "ExhibitorsComponent",
  mixins: [LpConfigMixin],
  components: {
    Separator,
    SearchInput,
    ExhibitorsPagerView,
    ListLoadingIndicator,
    DropdownSelect,
    PointIcon,
  },

  props: ["moduleData"],

  methods: {
    ...mapMutations("exhibitors", ["setFilters", "setSearch"]),

    onTagClicked(tag) {
      this.setFilters([tag.id]);
    },

    selectTags(tags) {
      this.setFilters(tags.map(it => this.allTags[it].id));
    },
  },

  computed: {
    ...mapGetters("components", ["getComponentById"]),
    ...mapGetters("exhibitors", ["visibleExhibitors", "isLoadingExhibitors"]),
    ...mapGetters("exhibitors", { getSearch: "search", getSelectedTagsIds: "filteredTagsIds", allTags: "allTags" }),
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters(["inboxEnabled"]),

    search: {
      get() {
        return this.getSearch;
      },
      set(value) {
        this.setSearch(value);
      },
    },

    selectedTagsIndexes() {
      return this.getSelectedTagsIds.map(selectedTagId => this.allTags.findIndex(tag => tag.id === selectedTagId));
    },

    exhibitorModuleFields: () => LpConfigConstants.EXHIBITORS_MODULE_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    exhibitors() {
      return this.visibleExhibitors(this.componentId);
    },

    componentId() {
      return this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.COMPONENT_ID, null);
    },

    component() {
      return this.getComponentById(this.componentId);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE, this.component.label);
    },

    pageSize() {
      return this.getFieldValueOrDefault(this.moduleData, this.exhibitorModuleFields.PAGE_SIZE, 4);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-background {
  background: #7f7f7f;
}
</style>
