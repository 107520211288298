<template>
  <div class="iframe" v-html="html">
    <!-- Content provided by the module data -->
  </div>
</template>

<script>
export default {
  name: "LiveWebinarIFrame",
  props: {
    html: {
      type: String, required: true,
    }
  },
};
</script>
