<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>

      <a class="twitter-timeline" :data-height="height" :data-theme="theme" data-tweet-limit="8" :href="twitterUrl"></a>
    </div>
  </article>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "TwitterComponent",
  mixins: [LpConfigMixin],
  components: { Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      scriptRef: null,
    };
  },

  mounted() {
    this.createTwitterScript();
  },

  beforeDestroy() {
    this.removeTwitterScript();
  },

  computed: {
    twitterModuleFields: () => LpConfigConstants.TWITTER_MODULE_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    twitterUrl() {
      return `${this.moduleData[this.twitterModuleFields.TWITTER_URL]}?ref_src=twsrc%5Etfw`;
    },

    theme() {
      return this.getFieldValueOrDefault(this.moduleData, this.twitterModuleFields.THEME, "light");
    },

    height() {
      return this.getFieldValueOrDefault(this.moduleData, this.twitterModuleFields.HEIGHT, "800");
    },

    scriptSrc() {
      return "https://platform.twitter.com/widgets.js";
    },
  },

  methods: {
    createTwitterScript() {
      if (this.twitterUrl) {
        this.scriptRef = document.createElement("script");
        this.scriptRef.type = "text/javascript";
        this.scriptRef.src = this.scriptSrc;
        this.scriptRef.charset = "utf-8";
        this.scriptRef.async = true;
        this.scriptRef = document.body.appendChild(this.scriptRef);
      }
    },

    removeTwitterScript() {
      if (this.scriptRef) {
        this.scriptRef.remove();
      }
    },
  },
};
</script>

<style scoped></style>
