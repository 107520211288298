<template>
  <partner-card :partner="partner" :component-id="componentId" :bgCardColor="bgCardColor" :open-external-link="openExternalLink" :is-borderless="isBorderless">
    <figure class="image">
      <img :src="pictureUrl"/>
    </figure>
  </partner-card>
</template>

<script>
import PartnerCard from "./PartnerCard";

export default {
  name: "PartnerView",

  components: {
    PartnerCard,
  },

  props: ["partner", "componentId", "openExternalLink", "isBorderless", "bgCardColor"],

  computed: {
    pictureUrl() {
      const picture = this.partner.picture;
      return picture && picture.file_thumbnail_750_url;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  display: flex;
  align-items: center;
  height: 100%;
}

.image img {
  margin: 0 auto;
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
}
</style>
