<template>
  <a
    class="box"
    :class="{'is-border-less': isBorderless}"
    :style="{ backgroundColor: bgCardColor, borderColor: bgCardColor }"
    @click="preventRedirect"
    @mousedown="reset"
    @mousemove="markAsDragged"
    v-if="clickable"
  >
    <slot></slot>
  </a>
  <div v-else>
    <div class="box" :style="{ backgroundColor: bgCardColor, borderColor: bgCardColor }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import PartnerInfoModal from "@/web/components/partners/PartnerInfoModal";

export default {
  name: "PartnerCard",

  props: ["partner", "componentId", "openExternalLink", "isBorderless", "bgCardColor"],

  data() {
    return {
      hasDragged: false,
    };
  },

  computed: {
    clickable() {
      return (this.partner.description && this.partner.description.length) || this.externalUrl;
    },

    externalUrl() {
      const url = this.partner.www;
      const hasHttp = url && url.startsWith("http");
      return hasHttp && this.partner.www;
    },
  },

  methods: {
    openPartnerInfo() {
      // logEvent(getAnalytics(), Constants.ANALYTICS_EVENTS.DISPLAY_SCREEN, {
      //   [Constants.ANALYTICS_PARAMETERS.VIEW_TAG]: ViewTags.PARTNER_PROFILE,
      //   [Constants.ANALYTICS_PARAMETERS.COMPONENT_ID]: this.componentId,
      //   [Constants.ANALYTICS_PARAMETERS.VIEW_ID]: this.partner.id,
      // });
      if (this.openExternalLink) {
        window.open(this.externalUrl, "_blank").focus();
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: PartnerInfoModal,
          hasModalCard: true,
          canCancel: true,
          trapFocus: true,
          props: {
            partner: this.partner,
            logoBgColor: this.bgCardColor,
          },
        });
      }
    },

    reset() {
      this.hasDragged = false;
    },

    markAsDragged() {
      this.hasDragged = true;
    },

    preventRedirect(ev) {
      ev.preventDefault();
      if (!this.hasDragged) {
        this.openPartnerInfo();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 190px;
  background-color: var(--partner-item-background-color);
}

</style>
