<template>
  <div ref="listContainer" :style="listContainerStyle" class="attendees-list-container columns">
    <template v-if="attendees && attendees.length">
      <div v-for="attendee in attendees" :key="attendee.user_identity_token" class="column is-half">
        <attendee-list-item
          :user="attendee"
          :is-current-user="attendee.user_identity_token === uuid"
          :inbox-enabled="inboxEnabled"
        ></attendee-list-item>
      </div>
      <list-loading-indicator :active="isLoading"></list-loading-indicator>
    </template>
    <div v-else-if="!isLoading" class="has-text-centered has-text-secondary column mt-2">
      {{ $t("attendees.empty_placeholder") }}
    </div>
    <div v-else class="column">
      <list-loading-indicator :active="isLoading"></list-loading-indicator>
    </div>
  </div>
</template>

<script>
import AttendeeListItem from "@/web/components/attendees/AttendeeListItem";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import { mapGetters } from "vuex";
import { throttle } from "@/shared/utils";

export default {
  name: "AttendeesList",
  components: { AttendeeListItem, ListLoadingIndicator },

  props: {
    attendees: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },

    maxHeight: {
      type: String,
      default: "49vh",
    },
  },

  data() {
    return {
      scrollListener: null,
    };
  },

  mounted() {
    this.addScrollListener();
  },

  beforeDestroy() {
    this.removeScrollListener();
  },

  computed: {
    ...mapGetters(["inboxEnabled"]),
    ...mapGetters("currentUser", ["uuid"]),

    scrollThreshold: () => 600,

    listContainerStyle() {
      return {
        maxHeight: this.maxHeight,
      };
    },
  },

  methods: {
    addScrollListener() {
      if (!this.scrollListener) {
        this.scrollListener = ev => {
          const attendeesList = ev.target;
          let isOnBottomOfList = attendeesList.scrollTop > attendeesList.scrollHeight - attendeesList.clientHeight - this.scrollThreshold;
          if (isOnBottomOfList) {
            this.onBottomOfScroll();
          }
        };
        this.$refs.listContainer.addEventListener("scroll", this.scrollListener);
      }
    },

    removeScrollListener() {
      if (this.visibilityListener) {
        this.$refs.listContainer.removeEventListener("scroll", this.scrollListener);
      }
    },

    onBottomOfScroll: throttle(function (newVal) {
      this.$emit("load-more");
    }, 1000),
  },
};
</script>

<style scoped>
.attendees-list-container {
  overflow-y: auto;
  overflow-x: hidden;
}

.columns {
  flex-wrap: wrap;
}

.column {
  padding-bottom: 0;
}
</style>
