<template>
  <purchase-step :step="0">
    <template>
      <h1 class="title is-3 mb-3">
        <template v-if="isMebleEvent || isProExpertEvent || isXEvent || isFlukeEvent">
          {{ $t("custom.meble_selected_invitation") }}
        </template>
        <template v-else-if="isPismEvent">
          {{ $t("custom.pism_2023_category") }}
        </template>
        <template v-else-if="!isDtsEvent">
          {{ $t("payment.selected_ticket") }}
        </template>
      </h1>

      <template v-if="!isAuthorized && isUpgradeEvent">
        <div class="columns is-mobile is-vcentered mb-0">
          <div class="column is-half">
            <ticket-card
              :id="-1"
              :type="''"
              title="ITCorner Only"
              price="999"
              currency="PLN"
              :price-invisible="false"
            ></ticket-card>
          </div>
          <div class="column is-narrow">
            <div class="purchase-ticket-ticket-buttons-container">
              <div
                class="ticket-button-quantity unselectable"
                :class="{ disabled: true }"
              >
                <minus-icon class="minus-icon"></minus-icon>
              </div>
              <div class="ticket-quantity-number unselectable">
                {{ 0 }}
              </div>
              <div
                class="ticket-button-quantity unselectable"
                :class="{ disabled: true }"
              >
                <plus-icon class="minus-icon"></plus-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="is-size-6 has-text-white mb-1">
          ITCORNER Only: Bilet na konferencję. Dostępne tylko dla Członków ITCORNER. Zaloguj się, a następnie wybierz pakiet.
        </div>

        <div class="columns is-mobile is-vcentered mb-0">
          <div class="column is-half">
            <ticket-card
              :id="-1"
              :type="''"
              title="ITCorner Only Plus"
              price="1399"
              currency="PLN"
              :price-invisible="false"
            ></ticket-card>
          </div>
          <div class="column is-narrow">
            <div class="purchase-ticket-ticket-buttons-container">
              <div
                class="ticket-button-quantity unselectable"
                :class="{ disabled: true }"
              >
                <minus-icon class="minus-icon"></minus-icon>
              </div>
              <div class="ticket-quantity-number unselectable">
                {{ 0 }}
              </div>
              <div
                class="ticket-button-quantity unselectable"
                :class="{ disabled: true }"
              >
                <plus-icon class="minus-icon"></plus-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="is-size-6 has-text-white mb-1">
          ITCORNER ONLY Plus: Bilet na konferencję + afterparty. Dostępne tylko dla Członków ITCORNER. Zaloguj się, a następnie wybierz pakiet.
        </div>
      </template>


      <div v-for="ticket in eventTickets" :key="ticket.id">
        <div class="columns is-mobile is-vcentered ticket-header-row">

          <div class="column ticket-card-container">
            <ticket-card
              :id="ticket.id"
              :type="''"
              :title="ticket.name"
              :custom-background-color="getTicketCustomBackgroundColor(ticket.id)"
              :price="getTicketPrice(ticket.price, ticket.id)"
              :showNetto="false"
              :currency="ticket.currency"
              :price-invisible="isPismEvent || isDolbyEvent || isJustJoinEvent || isMebleEvent || isTutloEvent || isDtsEvent || isProExpertEvent || isXEvent || isFlukeEvent || isPchetEvent || isTargiSpecjalEvent"
            ></ticket-card>
          </div>

          <div class="column is-narrow ticket-actions-container">
            <div class="purchase-ticket-ticket-buttons-container">
              <div
                class="ticket-button-quantity unselectable"
                @click.stop.prevent="subtractTicket(ticket.id)"
                :class="{ disabled: subtractButtonDisabled(ticket.id) }"
                :style="{ 'background-color': getTicketCustomBackgroundColor(ticket.id) }"
              >
                <minus-icon class="minus-icon"></minus-icon>
              </div>
              <div class="ticket-quantity-number unselectable">
                {{ getTicketQuantity(ticket.id) }}
              </div>
              <div
                class="ticket-button-quantity unselectable"
                @click.stop.prevent="addTicket(ticket.id)"
                :class="{ disabled: addButtonDisabled(ticket.id), customColor: getTicketCustomBackgroundColor(ticket.id) }"
                :style="{ 'background-color': getTicketCustomBackgroundColor(ticket.id) }"
              >
                <plus-icon class="minus-icon"></plus-icon>
              </div>
            </div>
          </div>

        </div>

        <div
          class="purchase-ticket-personal-form-container"
          v-for="(personalForm, order) in getPersonalFormsForTicket(ticket.id)"
          :key="`ticket-${ticket.id}-form-${order}`"
        >
          <h1 class="title is-5 mb-2" v-if="isMebleEvent || isProExpertEvent || isXEvent || isFlukeEvent">{{ `${$t("custom.meble_attendee")} ${order + 1}` }}</h1>
          <h1 class="title is-5 mb-2" v-else>{{ `${$t("payment.ticket")} ${order + 1}` }}</h1>
          <personal-details-form
            ref="form"
            :value="personalForm"
            :personal-forms="personalForms"
            :custom-color="getTicketCustomBackgroundColor(ticket.id)"
            :is-ped-event="isProExpertEvent"
            :email-errors="emailErrors"
            :email-disabled="!!(currentUser && personalForm.email === currentUser.email)"
            @input="updatePersonalForm(ticket.id, order, $event)"
            @clear-this-is-me-flags="clearThisIsMeFlags"
          ></personal-details-form>
          <event-addons-form
            v-if="ticket.event_addons.length"
            :order-key="order"
            :event-ticket="ticket"
            @input="updateAddonsForm(ticket.id, order, $event)"
          ></event-addons-form>
          <business-matching-filters-form
            ref="filterForm"
            v-if="businessMatchingFilterGroups && businessMatchingFilterGroups.length && bmFiltersEnabled"
            :order-key="order"
            :business-matching-filter-groups="businessMatchingFilterGroups"
            @input="updateFilterGroupsForm(ticket.id, order, $event)"
          ></business-matching-filters-form>
        </div>
      </div>
    </template>

    <template #buttons>
      <b-button class="button is-rounded ml-auto" type="is-primary" @click="next" :disabled="allTicketsQuantity <= 0"
        >{{ $t("payment.next") }}
      </b-button>
    </template>
  </purchase-step>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import PersonalDetailsForm from "@/web/components/forms/PersonalDetailsForm";
import TicketCard from "@/web/components/tickets/TicketCard";
import PurchaseStep from "./PurchaseStep";
import MinusIcon from "@/assets/common/icon_button_minus.svg";
import PlusIcon from "@/assets/common/icon_button_plus.svg";
import EventAddonsForm from "@/web/components/forms/EventAddonsForm";
import BusinessMatchingFiltersForm from "@/web/components/forms/BusinessMatchingFiltersForm";

export default {
  name: "PurchaseFirstStep",

  components: {
    BusinessMatchingFiltersForm,
    EventAddonsForm,
    PersonalDetailsForm,
    PurchaseStep,
    TicketCard,
    MinusIcon,
    PlusIcon,
  },

  data() {
    return {
      isInitialized: false,
      isDiscountCodeAppliedFromQuery: false,
      isInvalid: false,
      selectedTickets: {},
      personalForms: {},
      emailErrors: null,
    };
  },

  mounted() {
    this.setDiscount(null);
  },

  methods: {
    ...mapMutations("payment", ["setDiscount"]),
    ...mapActions("payment", ["setMultiplePersonalForms", "checkDiscountCode"]),

    getTicketPrice(price, ticketId) {
      let priceWithCurrency = price * 0.01;
      if(this.isUpgradeEvent || this.isKNM2024Event) {
        const factor = 1.0 + this.eventTicketsVat;
        return priceWithCurrency / factor;
      } else if (this.isEvExperienceEvent) {
        const factor = 1.0 + this.eventTicketsVat;
        if (ticketId === 518) {
          return (priceWithCurrency / factor) * 3;
        }
        if (ticketId === 515) {
          return priceWithCurrency / factor;
        }
        return priceWithCurrency;
      } else {
        return priceWithCurrency;
      }
    },

    getTicketCustomBackgroundColor(id) {
      if (this.isEvExperienceEvent && (id === 515 || id === 518)) {
        return '#02A2CE';
      }
      if (id === 580) {
        return '#FBBA00';
      }
      if (this.isAIBAConference) {
        return '#000000';
      }
      return null;
    },

    getFormDataForTicket(ticketId) {
      return this.customTicketForms[ticketId];
    },

    next() {
      const forms = this.$refs.form;
      forms.forEach(form => form.touch());
      let personalFormsValid = forms.reduce((isValid, personalForm) => {
        return isValid && personalForm.isValid();
      }, true);

      const filterForms = this.$refs.filterForm || [];
      filterForms.forEach(form => form.touch());
      let filterFormsValid = filterForms.reduce((isValid, filterForm) => {
        return isValid && filterForm.isValid();
      }, true);

      if (personalFormsValid && filterFormsValid) {
        this.setMultiplePersonalForms(this.personalForms);
        this.$emit("next");
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      } else {
        this.$nextTick().then(() => this.focusInvalidInput());
      }
    },

    focusInvalidInput() {
      const input = this.$el.querySelector("input.is-danger");
      if (input) {
        input.focus();
      }
    },

    getTicketQuantity(ticketId) {
      if (this.isMebleEvent && (ticketId === 430 || ticketId === 148 || ticketId === 494)) {
        return this.selectedTickets[ticketId] / 2;
      }
      if (this.isEvExperienceEvent && ticketId === 518) {
        return this.selectedTickets[ticketId] / 3;
      }
      return this.selectedTickets[ticketId];
    },

    getPersonalFormsForTicket(ticketId) {
      return this.personalForms[ticketId];
    },

    subtractButtonDisabled(ticketId) {
      return this.getTicketQuantity(ticketId) === 0;
    },

    addButtonDisabled(ticketId) {
      let eventTicket = this.getTicketById(ticketId);
      if(ticketId === 576) {
        return (eventTicket.tickets_left !== null && eventTicket.tickets_left <= this.selectedTickets[ticketId]) || this.selectedTickets[ticketId] >= 2;
      }
      if(this.isTutloEvent || this.isFastLaneEvent || this.isPismEvent || this.isFlukeEvent) {
        return Object.values(this.selectedTickets).some(it => it > 0);
      }
      if (this.isDolbyEvent || this.isDtsEvent) {
        return this.selectedTickets[ticketId] >= 1;
      }
      if(this.isUpgradeEvent) {
        return (eventTicket.tickets_left !== null && eventTicket.tickets_left <= this.selectedTickets[ticketId]) || this.selectedTickets[ticketId] >= 8;
      }
      if(this.isProExpertEvent) {
        return (eventTicket.tickets_left !== null && eventTicket.tickets_left <= this.selectedTickets[ticketId]) || this.selectedTickets[ticketId] >= 1;
      }
      if(this.isEvolutionEvent) {
        return (eventTicket.tickets_left !== null && eventTicket.tickets_left <= this.selectedTickets[ticketId]) || Object.values(this.selectedTickets).reduce((acc, value) => acc + value) >= 5;
      }
      return eventTicket.tickets_left !== null && eventTicket.tickets_left <= this.selectedTickets[ticketId];
    },

    subtractTicket(ticketId) {
      if (!this.subtractButtonDisabled(ticketId)) {
        if(this.isMebleEvent && (ticketId === 430  || ticketId === 148 || ticketId === 494)) {
          this.selectedTickets[ticketId] = this.selectedTickets[ticketId] - 2;
          this.selectedTickets = { ...this.selectedTickets };
        } else if(this.isEvExperienceEvent && ticketId === 518) {
          this.selectedTickets[ticketId] = this.selectedTickets[ticketId] - 3;
          this.selectedTickets = { ...this.selectedTickets };
        } else {
          this.selectedTickets[ticketId] = this.selectedTickets[ticketId] - 1;
          this.selectedTickets = { ...this.selectedTickets };
        }
      }
    },

    addTicket(ticketId) {
      if (!this.addButtonDisabled(ticketId)) {
        if (this.isMebleEvent) {
          if(ticketId === 430  || ticketId === 148 || ticketId === 494) {
            this.selectedTickets[ticketId] = this.selectedTickets[ticketId] + 2;
          } else {
            this.selectedTickets[ticketId] = this.selectedTickets[ticketId] + 1;
          }
          this.selectedTickets = { ...this.selectedTickets };
        } else if (this.isEvExperienceEvent && ticketId === 518) {
          this.selectedTickets[ticketId] = this.selectedTickets[ticketId] + 3;
          this.selectedTickets = { ...this.selectedTickets };
        } else {
          this.selectedTickets[ticketId] = this.selectedTickets[ticketId] + 1;
          this.selectedTickets = { ...this.selectedTickets };
        }
      }
    },

    updatePersonalForm(ticketId, order, formData) {
      this.personalForms[ticketId][order] = { ...this.personalForms[ticketId][order], ...formData };
      this.personalForms = { ...this.personalForms };
      this.setMultiplePersonalForms(this.personalForms);
    },

    updateAddonsForm(ticketId, order, formData) {
      this.personalForms[ticketId][order] = { ...this.personalForms[ticketId][order], addons: formData };
      this.personalForms = { ...this.personalForms };
      this.setMultiplePersonalForms(this.personalForms);
    },

    updateFilterGroupsForm(ticketId, order, formData) {
      this.personalForms[ticketId][order] = { ...this.personalForms[ticketId][order], filterGroups: formData };
      this.personalForms = { ...this.personalForms };
      this.setMultiplePersonalForms(this.personalForms);
    },

    clearThisIsMeFlags() {
      this.personalForms = Object.entries(this.personalForms).reduce((map, [key, forms]) => {
        map[key] = forms.map(form => {
          let updatedForm = { ...form };
          updatedForm.thisIsMe = false;
          return updatedForm;
        });
        return map;
      }, {});
    },

    handleAuthorization() {
      let currentUser = this.currentUser;
      if (currentUser) {
        this.personalForms = Object.entries(this.personalForms).reduce((map, [key, forms]) => {
          map[key] = forms.map(form => {
            let updatedForm = { ...form };
            if (currentUser && updatedForm.thisIsMe) {
              updatedForm.email = currentUser.email;
              updatedForm.firstName = currentUser.first_name;
              updatedForm.lastName = currentUser.last_name;
            } else if (currentUser && updatedForm.email === currentUser.email && !updatedForm.thisIsMe) {
              updatedForm.firstName = "";
              updatedForm.lastName = "";
              updatedForm.email = "";
            }
            return updatedForm;
          });
          return map;
        }, {});
      }
      this.setMultiplePersonalForms(this.personalForms);
    },

    async setDiscountCodeFromQueryParams() {
      if (this.queryParams && this.queryParams.length) {
        this.queryParams.forEach(queryParam => {
          if (queryParam[0] === "discount_code") {
            this.checkDiscountCode(queryParam[1]);
          }
        });
      }
    },

    showEmailErrors(errors) {
      this.emailErrors = errors;
    },
  },

  computed: {
    ...mapGetters("currentUser", ["currentUser"]),
    ...mapGetters("locales", ["currentLocale"]),
    ...mapState(["eventId"]),
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters(["eventTicketModules", "customTicketForms", "eventTicketsVat"]),
    ...mapState("payment", ["personalForm"]),
    ...mapGetters("eventTickets", { getAllTickets: "getAllTicketsFromEvent", getTicketById: "getById" }),
    ...mapGetters("businessMatchingFilters", ["getFiltersFromEvent"]),

    bmFiltersEnabled() {
      return this.isDeepTech2024;
    },

    isDeepTech2024() {
      return this.eventId === 1537;
    },

    isPismEvent() {
      return this.eventId === 1312;
    },

    isDtsEvent() {
      return this.eventId === 1411;
    },

    isProExpertEvent() {
      return this.eventId === 1419;
    },

    isXEvent() {
      return this.eventId === 1440;
    },

    isFlukeEvent() {
      return this.eventId === 1507;
    },

    isDolbyEvent() {
      return this.eventId === 1318;
    },

    isJustJoinEvent() {
      return this.eventId === 1273;
    },
    
    isPchetEvent() {
      return this.eventId === 1558;
    },

    isTargiSpecjalEvent() {
      return this.eventId === 1575;
    },

    isMebleEvent() {
      return this.eventId === 1343 || this.eventId === 245;
    },

    isTutloEvent() {
      return this.eventId === 1408;
    },

    isUpgradeEvent() {
      return this.eventId === 1355;
    },

    isEvolutionEvent() {
      return this.eventId === 1509;
    },

    isKNM2024Event() {
      return this.eventId === 1441;
    },

    isEvExperienceEvent() {
      return this.eventId === 1425;
    },

    isKNM20Event() {
      return this.eventId === 1441;
    },

    isFastLaneEvent() {
      return this.eventId === 1448;
    },

    isIgrzyskaWolnosciEvent() {
      return this.eventId === 1468;
    },

    isAIBAConference() {
      return this.eventId === 1559;
    },

    eventTickets() {
      if(this.isUpgradeEvent) {
        return [...this.getAllTickets].sort((a,b) => {
          if (a.id === 475) return -1;
          if (b.id === 475) return 1;
          if (a.id === 476) return -1;
          if (b.id === 476) return 1;
          return a.name.localeCompare(b.name);
        });
      } else if (this.isAIBAConference) {
        const sortOrder = [727, 726, 729, 728];
        return [...this.getAllTickets].sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id));
      } else if(window.location.host.includes("architekt.lignumsoft")) {
        return [this.getTicketById(494), this.getTicketById(495)];
      } else if(this.isEvExperienceEvent) {
        const ticketId = this.currentUserTicketId;
        if (ticketId) {
          return this.getAllTickets.filter(t => t.id === Number(ticketId));
        }
        return this.getAllTickets;
      } else if (this.isKNM20Event) {
        const ticketId = this.currentUserTicketId;
        if (ticketId) {
          return this.getAllTickets.filter(t => t.id === Number(ticketId));
        }
        const ticketIdsToHide = [591, 663, 662, 539];
        return this.getAllTickets.filter(t => !ticketIdsToHide.includes(t.id));
      } else if (this.isIgrzyskaWolnosciEvent) {
        const ticketId = this.currentUserTicketId;
        if (ticketId) {
          return this.getAllTickets.filter(t => t.id === Number(ticketId));
        }
        const ticketIdsToHide = [798, 799];
        return this.getAllTickets.filter(t => !ticketIdsToHide.includes(t.id));
      } else {
        return this.getAllTickets;
      }
    },

    currentUserTicketId() {
      return Number(this.$route.params.ticketId);
    },

    queryParams() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return Object.entries(Object.fromEntries(urlSearchParams.entries()));
    },

    allTicketsQuantity() {
      return Object.values(this.selectedTickets).reduce((acc, count) => acc + count, 0);
    },

    businessMatchingFilterGroups() {
      return this.getFiltersFromEvent(this.eventId);
    }
  },

  watch: {
    currentUser: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!newValue) {
          this.isInitialized = true;
        } else if (newValue && !oldValue) {
          this.handleAuthorization();
        }
      },
    },

    // personalForm: {
    //   immediate: true,
    //   handler: function (newValue) {
    //     // if (newValue && !this.isInitialized && this.currentUserTicketId && this.eventTickets.length) {
    //     //   if(this.isMebleEvent) {
    //     //     this.personalForms[this.currentUserTicketId].forEach((form, index) => {
    //     //       if(form.thisIsMe) {
    //     //         this.personalForms[this.currentUserTicketId][index] = newValue;
    //     //       }
    //     //     });
    //     //   } else {
    //     //     this.personalForms[this.currentUserTicketId] = [newValue];
    //     //   }
    //     //   this.personalForms = { ...this.personalForms };
    //     //   this.isInitialized = true;
    //     // }
    //   },
    // },

    eventTickets: {
      immediate: true,
      handler: function (newValue) {
        if (Object.values(this.selectedTickets).length !== newValue.length && newValue.length) {
          let selectedTickets = newValue.reduce((map, ticket) => {
            map[ticket.id] = 0;
            return map;
          }, {});
          if (this.currentUserTicketId) {
            if(this.currentUserTicketId === 455 || this.currentUserTicketId === 452) {
              if(this.isAuthorized) {
                selectedTickets[this.currentUserTicketId] = 1;
              }
            } else if(this.currentUserTicketId === 430  || this.currentUserTicketId === 148 || this.currentUserTicketId === 494) {
              selectedTickets[this.currentUserTicketId] = 2;
            } else if(this.currentUserTicketId === 518) {
              selectedTickets[this.currentUserTicketId] = 3;
            } else {
              selectedTickets[this.currentUserTicketId] = 1;
            }
          }
          this.selectedTickets = selectedTickets;
        }
      },
    },

    //Updating personal forms on selected tickets count change
    selectedTickets: {
      immediate: true,
      handler: function (newValue) {
        if (newValue === Object(newValue)) {
          let allTicketsCount = Object.values(newValue).reduce((acc, count) => acc + count, 0);
          this.personalForms = Object.entries(newValue).reduce((map, [key, ticketsCount]) => {
            let newFormsArray = Array.from({ length: ticketsCount }, () => ({ thisIsMe: allTicketsCount === 1 }));
            if(this.isMebleEvent && (key === "430"  || key === "148" || key === "494") && ticketsCount) {
              newFormsArray[0].thisIsMe = true;
            }
            if(this.isEvExperienceEvent && (key === "518") && ticketsCount) {
              newFormsArray[0].thisIsMe = true;
            }
            if (this.personalForms[key]) {
              this.personalForms[key].forEach((personalForm, order) => {
                if (newFormsArray.length > order || !personalForm) {
                  if (allTicketsCount === 1) {
                    newFormsArray[order] = {
                      ...personalForm,
                      thisIsMe: true,
                    };
                  } else {
                    newFormsArray[order] = personalForm;
                  }
                }
              });
            }
            map[key] = newFormsArray;
            return map;
          }, {});
          this.setMultiplePersonalForms(this.personalForms);
          if (!this.isDiscountCodeAppliedFromQuery) {
            this.setDiscountCodeFromQueryParams();
          }
          if (allTicketsCount === 1 || (this.isMebleEvent && allTicketsCount === 2)) {
            this.handleAuthorization();
          }
        }
      },
    },
  },
};
</script>

<style scoped>
  .ticket-card-container {
    width: 50%;
  }

  .ticket-actions-container {
    margin: 0 auto;
  }

  @media(max-width: 678px) {
    .ticket-card-container {
      width: 100%;
    }
  }

  @media(max-width: 424px) {
    .ticket-header-row {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
  }
</style>
