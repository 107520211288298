export const state = () => ({
  lastChangedForComponentId: null,
  viewsTree: {},
  scrollTopPos: {},
});

export const mutations = {
  changeActiveView(state, view) {
    state.lastChangedForComponentId = view.componentId;
    const componentViews = state.viewsTree[view.componentId] || [];
    state.viewsTree = {
      ...state.viewsTree,
      [view.componentId]: [
        ...componentViews,
        {
          view: view.type,
          data: view.data,
          componentId: view.componentId,
        },
      ],
    };
  },
  navigateBack(state, componentId) {
    const componentViews = [...state.viewsTree[componentId]];
    if (!!componentViews && componentViews.length > 1) {
      componentViews.pop();
      state.viewsTree = {
        ...state.viewsTree,
        [componentId]: componentViews,
      };
    } else {
      state.viewsTree = {
        ...state.viewsTree,
        [componentId]: [],
      };
    }
  },

  setScrollTopPos(state, { top, componentId, componentActiveView }) {
    if (componentActiveView && componentActiveView.view === WidgetViewTypes.MAIN) {
      state.scrollTopPos = { ...state.scrollTopPos, [componentId]: top };
    }
  }
};

export const actions = {
  setScrollTopPos({ commit, getters }, { top, componentId }) {
    const componentActiveView = getters.activeView(componentId);
    commit('setScrollTopPos', { top, componentId, componentActiveView });
  }
};

export const getters = {
  viewsTree(state) {
    return state.viewsTree;
  },
  activeView: state => componentId => {
    const componentViews = state.viewsTree[componentId];
    if (!componentViews || componentViews.length === 0) {
      return {
        view: WidgetViewTypes.MAIN,
        data: null,
        componentId,
      };
    } else {
      const activeView = componentViews[componentViews.length - 1];
      return activeView;
    }
  },
  scrollTopPos: state => componentId => {
    return state.scrollTopPos[componentId];
  },
  lastChangedForComponentId: state => {
    return state.lastChangedForComponentId;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export const WidgetViewTypes = {
  MAIN: "main_view",
  SPEAKER_INFO: "speaker_info_view",
  SESSION_DETAILS: 'session_details_view',
  EXHIBITOR_DETAILS: 'exhibitor_details_view',
};
