<template>
  <div>
    <div class="srk-summary-child-name">{{ name }}</div>
    <div class="srk-summary-child-subtitle">
      <div>{{ $t("custom.srk_category") }}</div>
      <div class="is-blue">{{ category }}</div>
    </div>
    <div class="srk-summary-child-subtitle">
      <div>{{ $t("custom.srk_start") }}</div>
      <div class="is-blue">{{ startDate }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from '@/shared/utils/day';

export default {
  name: "SrkChildSummary",

  props: {
    child: {
      type: Object,
    },
    childTicketName: {
      type: String,
    },
    childNameString: {
      type: String,
    },
  },

  computed: {
    ...mapState(["timezone"]),

    name() {
      return this.childNameString || (this.child && this.child.childName);
    },

    startDate() {
      let ticketName = this.childTicketName || (this.child && this.child.ticket && this.child.ticket.name);
      return ticketName && dayjs(ticketName.split(".")[1]).tz(this.timezone).format(" dddd DD MMM HH:mm");
    },

    category() {
      if (this.childTicketName) {
        return this.categories[Number.parseInt(this.childTicketName.split(".")[0])];
      } else if (this.child && "category" in this.child) {
        return this.categories[this.child.category];
      } else {
        return "";
      }
    },

    categories() {
      return this.$t("custom.srk_child_form_categories").map(it => ` ${it.title} (${it.subtitle})`);
    },
  },
};
</script>

<style scoped></style>
