<template>
  <paginated-content :items="speakers" :perPage="pageSize">
    <template v-slot="{ displayItems }">
      <div class="columns">
        <div v-for="(speaker, index) in displayItems" :key="index" class="column is-half">
          <speaker-list-item-view :data="speaker" :show-country="showCountry" :lp-mode="lpMode"></speaker-list-item-view>
        </div>
      </div>
    </template>
  </paginated-content>
</template>

<script>
import PaginatedContent from "@/shared/components/common/pagination/PaginatedContent";
import SpeakerListItemView from "@/shared/components/speakers/SpeakerListItemView";

export default {
  name: "SpeakersPagerView",

  props: ["speakers", "showCountry", "lpMode", "pageSize"],

  components: {
    PaginatedContent,
    SpeakerListItemView
  },
};
</script>

<style lang="scss" scoped>
.columns {
  flex-wrap: wrap;
}
</style>
