<template>
  <div class="button-container">
    <div>
      <button-image class="button-icon"></button-image>
    </div>
    <div>
      <div class="top-text notranslate">
        {{ $t("mobile_app_info.store_button_get_it_on") }}
      </div>
      <div class="bottom-text notranslate">
        {{ $t("mobile_app_info.store_button_google_play") }}
      </div>
    </div>
  </div>
</template>

<script>
import ButtonImage from "@/assets/icon_google_play.svg";

export default {
  name: "GooglePlayButton",
  components: { ButtonImage }
};
</script>

<style scoped>
.button-container {
  width: 11.555rem;
  height: 3.888rem;
  background: black;
  border-radius: 0.277rem;
  display: flex;
  flex-direction: row;
}
.top-text {
  font-style: normal;
  font-weight: normal;
  font-size: 0.888rem;
  line-height: 129.9%;
  color: #ffffff;
  margin-top: 0.555rem;
}
.bottom-text {
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 1.222rem;
  line-height: 129.9%;
}
.button-icon {
  margin: 0.944rem 1.0272rem 0.9738rem 0.944rem;
}

@media only screen and (max-width: 768px) {
  .button-container {
    width: 10rem
  }
  .button-icon {
    margin: 0.944rem 0.944rem 0.9738rem 0.944rem;
  }
  .top-text {
    margin-top: 0.777rem;
    font-size: 0.777rem;
  }
  .bottom-text {
    font-size: 0.999rem;
    line-height: 129.9%;
  }
}
</style>
