<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>

      <div class="ped-info-grid">

        <div class="ped-info-card">
          <div class="ped-info-card-header">
            <div class="ped-info-card-img-container">
              <img class="ped-info-card-img" src="@/assets/custom/ped/info-section/idea.png" alt="">
              <div class="ped-info-card-img-primary"></div>
            </div>
            <h3 class="ped-info-card-title">{{$t("custom.ped_info_card_1_title")}}</h3>
          </div>
          <div class="ped-info-card-body">
            <p class="ped-info-card-desc">{{$t("custom.ped_info_card_1_desc")}}</p>
          </div>
        </div>

        <div class="ped-info-card">
          <div class="ped-info-card-header">
            <div class="ped-info-card-img-container">
              <img class="ped-info-card-img" src="@/assets/custom/ped/info-section/graph_report.png" alt="">
              <div class="ped-info-card-img-primary"></div>
            </div>
            <h3 class="ped-info-card-title">{{$t("custom.ped_info_card_2_title_p1")}}<br/>{{$t("custom.ped_info_card_2_title_p2")}}</h3>
          </div>
          <div class="ped-info-card-body">
            <p class="ped-info-card-desc">{{$t("custom.ped_info_card_2_desc")}}</p>
          </div>
        </div>


        <div class="ped-info-card">
          <div class="ped-info-card-header">
            <div class="ped-info-card-img-container">
              <img class="ped-info-card-img" src="@/assets/custom/ped/info-section/conference_foreground_selected.png" alt="">
              <div class="ped-info-card-img-primary"></div>
            </div>
            <h3 class="ped-info-card-title">{{$t("custom.ped_info_card_3_title_p1")}}<br/>{{$t("custom.ped_info_card_3_title_p2")}}</h3>
          </div>
          <div class="ped-info-card-body">
            <p class="ped-info-card-desc">{{$t("custom.ped_info_card_3_desc")}}</p>
          </div>
        </div>


        <div class="ped-info-card">
          <div class="ped-info-card-header">
            <div class="ped-info-card-img-container">
              <img class="ped-info-card-img" src="@/assets/custom/ped/info-section/radar_plot.png" alt="">
            </div>
            <h3 class="ped-info-card-title">{{$t("custom.ped_info_card_4_title")}}</h3>
          </div>
          <div class="ped-info-card-body">
            <p class="ped-info-card-desc">{{$t("custom.ped_info_card_4_desc")}}</p>
          </div>
        </div>

        <div class="ped-info-card">
          <div class="ped-info-card-header">
            <div class="ped-info-card-img-container">
              <img class="ped-info-card-img" src="@/assets/custom/ped/info-section/omnichannel.png" alt="">
            </div>
            <h3 class="ped-info-card-title">{{$t("custom.ped_info_card_5_title")}}</h3>
          </div>
          <div class="ped-info-card-body">
            <p class="ped-info-card-desc">{{$t("custom.ped_info_card_5_desc")}}</p>
          </div>
        </div>

        <div class="ped-info-card">
          <div class="ped-info-card-header">
            <div class="ped-info-card-img-container">
              <img class="ped-info-card-img is-pro-expert-logo" src="@/assets/custom/ped/info-section/pro_academy_logo.png" alt="">
            </div>
            <h3 class="ped-info-card-title">{{$t("custom.ped_info_card_6_title")}}</h3>
          </div>
          <div class="ped-info-card-body">
            <p class="ped-info-card-desc">{{$t("custom.ped_info_card_6_desc")}}</p>
          </div>
        </div>


      </div>

    </div>
  </article>
</template>

<script>

import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "PedInfoComponent",
  mixins: [LpConfigMixin],
  components: { Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    pageSize() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.PAGE_SIZE, 8);
    },

    ticketViewType() {
      return LpConfigConstants.EVENT_TICKET_VIEW_TYPES.CINEMA;
    },
  },
};
</script>

<style scoped lang="scss">

  .container {
    padding-top: 80px;
  }

  .ped-info-card-title {
    color: #fff;
    font-size: 26px;
    font-weight: 700;
  }

  .ped-info-card-desc {
    color: #AFAFAF;
    font-size: 20px;
    font-weight: 400;
  }

  .ped-info-grid {
    margin-top: 80px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  }

  @media(max-width: 896px) {
    .ped-info-grid {
      grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    }
  }

  .ped-info-card {
    flex: 1;
    padding: 28px;
  }

  @media(min-width: 1386px) {
    .ped-info-card:nth-child(1) {
      border-right: 1px solid #3f3f3f;
      border-bottom: 1px solid #3f3f3f;
    }

    .ped-info-card:nth-child(2) {
      border-right: 1px solid #3f3f3f;
      border-bottom: 1px solid #3f3f3f;
    }

    .ped-info-card:nth-child(3) {
      border-bottom: 1px solid #3f3f3f;
    }

    .ped-info-card:nth-child(4) {
      border-right: 1px solid #3f3f3f;
    }

    .ped-info-card:nth-child(5) {
      border-right: 1px solid #3f3f3f;
    }
  }

  @media(max-width: 1386px) {
    .ped-info-card:nth-child(1) {
      border-right: 1px solid #3f3f3f;
      border-bottom: 1px solid #3f3f3f;
    }

    .ped-info-card:nth-child(2) {
      border-bottom: 1px solid #3f3f3f;
    }

    .ped-info-card:nth-child(3) {
      border-bottom: 1px solid #3f3f3f;
      border-right: 1px solid #3f3f3f;
    }

    .ped-info-card:nth-child(4) {
      border-bottom: 1px solid #3f3f3f;
    }

    .ped-info-card:nth-child(5) {
      border-right: 1px solid #3f3f3f;
    }
  }

  @media(max-width: 796px) {
    .ped-info-card {
      border: 0 !important;
    }
  }

  .ped-info-card-header {
    display: flex;
    align-items: center;
  }

  .ped-info-card-img-container {
    min-width: 72px;
    width: 72px;
    height: 72px;
    position: relative;
    margin-right: 16px;
  }

  .ped-info-card-img {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;

    &.is-pro-expert-logo {
      width: auto;
      height: 100%;
    }
  }

  .ped-info-card-img-primary {
    z-index: 1;
    width: 100%;
    height: 20px;
    background-color: var(--primary-color);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .ped-info-card-body {
    margin-top: 16px;
  }
</style>
