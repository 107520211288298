<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>

      <div class="columns is-vcentered is-variable is-7">
        <div class="column is-half">
          <div class="unity-image">
            <div class="unity-boxes">
              <div class="unity-box">
                <div class="unity-box-subcontainer">
                  <div class="unity-box-title">
                    <span>{{ $t("custom.unity_topics_box_title_1") }}</span>
                    <span class="unity-box-title-plus">+</span>
                  </div>
                  <span class="unity-box-subtitle">{{ $t("custom.unity_topics_box_text_1") }}</span>
                </div>
                <div class="unity-box-subcontainer">
                  <div class="unity-box-title">
                    <span>{{ $t("custom.unity_topics_box_title_2") }}</span>
                    <span class="unity-box-title-plus">+</span>
                  </div>
                  <div class="unity-box-subtitle">{{ $t("custom.unity_topics_box_text_2") }}</div>
                </div>
                <div class="unity-box-subcontainer">
                  <div class="unity-box-title">
                    <span>{{ $t("custom.unity_topics_box_title_3") }}</span>
                  </div>
                  <div class="unity-box-subtitle">{{ $t("custom.unity_topics_box_text_3") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="unity-title">{{ $t("custom.unity_topics_title") }}</div>
          <div class="unity-subtitle">{{ $t("custom.unity_topics_subtitle") }}</div>
          <div class="unity-description">{{ $t("custom.unity_topics_description") }}</div>
          <div class="unity-buttons-container">
            <button class="button is-primary" @click="scrollToEventTicketsModule" v-if="ticketsButtonVisible">{{ $t("custom.unity_topics_button_1") }}</button>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import { mapGetters } from "vuex";
import { navScrollTo } from "@/shared/utils";

export default {
  name: "UnityCustomComponent",

  mixins: [LpConfigMixin],
  components: { Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  methods: {
    scrollToEventTicketsModule() {
      this.scrollToModule(`#module-${this.eventTicketModules[0].id}`);
    },

    scrollToModule(hash) {
      navScrollTo(hash, "smooth");
    },
  },

  computed: {
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters(["eventTicketModules"]),

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    ticketsButtonVisible() {
      return this.eventTicketModules && this.eventTicketModules.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.unity-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 109%;
  color: var(--primary-color);
}

.unity-subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 109%;
  margin-top: 6px;
  color: var(--text-color-primary);
}

.unity-description {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 155%;
  color: var(--text-color-primary);
  margin-top: 12px;
}

.unity-buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  column-gap: 22px;

  .button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    width: 173px;
    min-height: 50px;

    &.is-outlined {
      color: white;
      border-color: white;

      &:hover {
        border-color: var(--primary-color);
        background-color: var(--primary-color-hover);
      }
    }
  }
}

.unity-image {
  position: relative;
  height: 309px;
  background-image: url(~@/assets/custom/unity/unity_2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .unity-boxes {
    position: absolute;
    right: -30px;
    top: 37px;

    .unity-box {
      background: var(--unity-blue);
      padding: 13px 20px;
      max-width: 239px;
      margin-bottom: 14px;
      text-align: center;

      .unity-box-subcontainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 8px;

        .unity-box-title {
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          color: white;
          flex: 0 1 56px;
          text-align: right;
        }

        .unity-box-title-plus {
          font-weight: 500;
          color: var(--primary-color);
        }

        .unity-box-subtitle {
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          color: white;
          text-align: left;
          flex: 1 0;
        }
      }
    }
  }
}
</style>
