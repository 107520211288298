<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>

      <div class="columns is-vcentered is-variable is-7">
        <div class="column is-half">
          <div class="pism-image">
            <div class="pism-boxes">
              <div class="pism-box">
                <div class="pism-box-title">
                  <span>{{ $t("custom.pism_topics_box_title_1") }}</span>
                  <!--                  <span class="pism-box-title-plus">+</span>-->
                </div>
                <div class="pism-box-subtitle">{{ $t("custom.pism_topics_box_text_1") }}</div>
              </div>

              <div class="pism-box">
                <div class="pism-box-title">
                  <span>{{ $t("custom.pism_topics_box_title_2") }}</span>
                  <span class="pism-box-title-plus">+</span>
                </div>
                <div class="pism-box-subtitle">{{ $t("custom.pism_topics_box_text_2") }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="pism-title">{{ $t("custom.pism_2023_topics_title") }}</div>
          <div class="pism-subtitle">{{ $t("custom.pism_2023_topics_subtitle") }}</div>
          <div class="pism-description">{{ $t("custom.pism_2023_topics_description") }}</div>
          <div class="pism-buttons-container">
            <button class="button is-primary has-text-white" @click="scrollToModule('#module-4')">{{ $t("custom.pism_topics_button_1") }}</button>
            <button class="button is-primary is-outlined" @click="navigateToRegister" v-if="!ticket">{{ $t("custom.pism_topics_button_2") }}</button>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import { navScrollTo } from "../../../shared/utils/nav-scroll-to";
import Constants from "@/web/constants";
import {mapGetters} from "vuex";

export default {
  name: "Pism2023TopicsCustomComponent",
  mixins: [LpConfigMixin],
  components: { Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("userTickets", ["ticket"]),

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },
  },

  methods: {
    scrollToModule(hash) {
      navScrollTo(hash, "smooth");
    },

    navigateToRegister() {
      this.$router.push({name: Constants.ROUTE_BUY_TICKET_NO_SELECTION});
    },
  },
};
</script>

<style lang="scss" scoped>
.pism-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 109%;
  color: var(--primary-color);
}

.pism-subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 109%;
  margin-top: 6px;
  color: var(--text-color-primary);
}

.pism-description {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 155%;
  color: var(--text-color-primary);
  margin-top: 12px;
}

.pism-buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  column-gap: 22px;

  .button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    width: 173px;

    &.is-primary {
      color: black;
    }

    &.is-outlined {
      color: white;
      border-color: white;

      &:hover {
        border-color: var(--primary-color);
        background-color: var(--primary-color-hover);
      }
    }
  }
}

.pism-image {
  position: relative;
  height: 309px;
  border-radius: 4px;
  background-image: url(~@/assets/custom/pism/pism_topics.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .pism-boxes {
    position: absolute;
    right: -30px;
    top: 37px;

    .pism-box {
      background: #ffffff;
      border-radius: 4px;
      padding: 13px 15px;
      max-width: 239px;
      margin-bottom: 14px;
      text-align: center;

      .pism-box-title {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        color: black;
      }

      .pism-box-title-plus {
        font-weight: 500;
        color: var(--primary-color);
      }

      .pism-box-subtitle {
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        color: black;
      }
    }
  }
}
</style>
